import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getReviews } from '../../components/reviews/ReviewAPI';
import { Grid, Box, CardContent, CardActions, Skeleton, Typography, Card, Button } from '@mui/material';
import { styled } from '@mui/system';
import { ReviewCard, ReviewCardSkeleton } from '../../components/reviews/StationReview';
import { getSingleUser, getUser } from '../../components/user/UserApi';
import { getStations } from '../../components/stations/StationsAPI';
import { Flag, ThumbUpOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const ReviewsList = () => {
  const [ users, setUsers] = useState([]);
  const [ review, setReviews] = useState([]);
  const [ stations, setStations ] = useState([]);
  const authenticatedUser = useSelector((state) => state.userAuth);

  useEffect(() => {
    fetchReviews();
    fetchAllUsers();
    fetchStations();
  }, []);
  
  const fetchStations = async () => {
    try {
      const data = await getStations();
      setStations(data.station);
    } catch (error) {
      console.error('Error fetching Stations:', error);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const data = await getUser();
      setUsers(data);
    } catch (error) {
        console.error('Error fetching Users:', error);
    }
  }

  const fetchReviews = async () => {
    try {
      const data = await getReviews();
      setReviews(data.reviews);
        
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  const sortedReviews = review
    .flatMap(station => station.reviews)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const updatedSortedReviews = sortedReviews.map((reviewitem) => {
    const station = review.find((station) =>
      station.reviews.some((r) => r._id === reviewitem._id)
    );
    const matchingStation = stations?.find((s) => s.station_id === Number(station?.station_id));
    return {
      ...reviewitem,
      station_id: matchingStation?._id, // Use the _id from matchingStation
      station_name: station.station_name,
    };
  });

  return (
    <div className="container">
      <h1>All Reviews</h1>
      <Grid container spacing={2}>
        {updatedSortedReviews.length === 0 ? (
          <ReviewCardSkeleton xs={12} sm={6} md={4} />
          ) : (
            updatedSortedReviews.slice(0, 9).map(reviewitem => (
              <Grid key={reviewitem._id} item xs={12} sm={6} md={4}>
                <ReviewCard reviews={review && reviewitem} users={users && users} userId={authenticatedUser?.user?._id}/>
              </Grid>
            ))
          )
        }
      </Grid>
    </div>
  );
};

export default ReviewsList;
