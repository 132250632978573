import axios from 'axios';

const API_URL = 'https://station-find-bke.onrender.com/api/station';
// const API_URL = 'http://localhost:5000/api/station';
export const getStations = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching Stations:', error);
    throw error;
  }
};
export const getSingleStation = async (station_id) => {
  try {
    const response = await axios.get(`${API_URL}/${station_id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Stations:', error);
    throw error;
  }
};

export const addStation = async (newStation) => {
  try {
    const response = await axios.post(`${API_URL}`, newStation);
    return response.data;
  } catch (error) {
    console.error('Error adding Station:', error);
    throw error;
  }
};

export const editStation = async (updatedStation) => {
  try {
    const response = await axios.patch(`${API_URL}/${updatedStation.station_id}`, updatedStation);
    return response.data;
  } catch (error) {
    console.error('Error editing Station:', error);
  }
}