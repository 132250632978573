import axios from 'axios';

const API_URL = 'http://localhost:5000/api/report-review';

// Function to fetch reported reviews
export const getReportedReviews = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data; // Assuming your API returns data in the format { reports: [...] }
  } catch (error) {
    throw error; // Handle the error in your component
  }
};


// Function to submit a report
export const submitReport = async (reportData) => {
  try {
    const response = await axios.post(API_URL, reportData);
    return response.data; // You can return any response data you receive from the server
  } catch (error) {
    throw error; // Handle the error in your component
  }
};

export const resolveReport = async (id) => {
  try {
    const response = await axios.put(`${API_URL}/${id}/resolve`);
    return response.data;
  } catch (error) {
    throw error
  }
}