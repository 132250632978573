// userSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logout, setAuthToken } from "../middleware/auth";

const APP_URL = 'https://station-find-bke.onrender.com/api';
// const APP_URL = 'http://localhost:5000/api/'
// Register User Async Thunk
export const registerUserAction = createAsyncThunk(
  "user/register",
  async (user, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(`${APP_URL}register`, user, config);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error);
    }
  }
);

// Login User Async Thunk
export const loginUserAction = createAsyncThunk(
  "user/login",
  async (userData, { rejectWithValue, dispatch }) => {
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    try {
      const { data } = await axios.post(`${APP_URL}login`, userData, config);
      setAuthToken(data.token);
      
      // Store the token without double quotes
      localStorage.setItem("token", data.token);
      
      localStorage.setItem("role", JSON.stringify(data.user.role));
      localStorage.setItem("loginTimestamp", JSON.stringify(new Date().getTime())); // Store login timestamp
      axios.defaults.headers.common['Authorization'] = data.token;
      dispatch(setUser(data)); // Dispatch action to store user data in Redux store
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Function to check and remove item from localStorage if it's older than 5 days
function checkAndRemoveItem(key) {
  const loginTimestamp = JSON.parse(localStorage.getItem("loginTimestamp"));
  if (loginTimestamp) {
    const currentTime = new Date().getTime();
    const fiveDaysInMillis = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

    if (currentTime - loginTimestamp >= fiveDaysInMillis) {
      localStorage.removeItem(key);
      localStorage.removeItem("loginTimestamp"); // Remove the timestamp as well
      console.log(`Item with key '${key}' removed from localStorage.`);
    }
  }
}

// Call this function to check and remove the item if needed
checkAndRemoveItem("token");
checkAndRemoveItem("role");


// Action to set user data in the Redux store
export const setUser = (userData) => {
  return {
    type: 'user/setUser',
    payload: userData,
  };
};

export const logoutUserAction = createAsyncThunk('user/logout', async () => {
  try {
    // Clear the token from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("loginTimestamp");

    // Clear the Authorization header and Redux store
    delete axios.defaults.headers.common['Authorization'];

    return null; // Return null or some other value to indicate success
  } catch (error) {
    throw error;
  }
});


const isAuthorizationSet = !!axios.defaults.headers.common['Authorization'];

const userLoginFromStorage = localStorage.getItem("token")
  ? true
  : false;
  
const userLoginFromHeaders = isAuthorizationSet
  ? JSON.parse(localStorage.getItem("token"))
  : null;
const initialState = {
  user: userLoginFromStorage === true ? JSON.parse(localStorage.getItem("role")) : null,
  isAuthenticated: userLoginFromStorage,
  loading: false,
  registrationError: null,
  loginError: null,
  loginSuccess: null,
  token: userLoginFromHeaders,
  registrationSuccess: null
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // Add a reducer to set user data in the Redux store
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
  },
  extraReducers: (builder) => {
    // Register
    builder.addCase(registerUserAction.pending, (state, action) => {
      state.loading = true;
      state.registrationError = null;
    });
    builder.addCase(registerUserAction.fulfilled, (state, action) => {
      state.loading = false;
      state.registrationError = null;
      state.registrationSuccess = action.payload.message
    });
    builder.addCase(registerUserAction.rejected, (state, action) => {
      state.loading = false;
      state.registrationError = action.payload.response?.data.message;
    });

    // Login
    builder.addCase(loginUserAction.pending, (state) => {
      state.loading = true;
      state.loginError = null;
    });
    builder.addCase(loginUserAction.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.loading = false;
      state.loginError = null;
      state.loginSuccess = action.payload.message;
      setAuthToken(action.payload.token);
    });
    builder.addCase(loginUserAction.rejected, (state, action) => {
      state.loading = false;
      state.loginError = action.payload.message;
    });

    builder.addCase(logoutUserAction.pending, (state) => {
      state.loading = true;
    });
    
    builder.addCase(logoutUserAction.fulfilled, (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.loading = false;
    });
    
    builder.addCase(logoutUserAction.rejected, (state) => {
      state.loading = false;
    });
    
  },
});


// export const { setUser } = usersSlice.actions;

export default usersSlice.reducer;
