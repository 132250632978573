
import ReviewsList from './reviews/ReviewsList';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getStations } from '../components/stations/StationsAPI';
import { Button, CircularProgress } from '@mui/material';

const Home = () => {
    const [stations, setStations] = useState([]);
    const [filteredStations, setFilteredStations] = useState([]);

    
    const fetchStationsData = async () => {
        try {
          const data = await getStations();
          setStations(data.station);
        //   setFilteredStations(data.station);
        } catch (error) {
          console.error('Error fetching Stations:', error);
        }
    };
    
    useEffect(() => {
        fetchStationsData()
    }, [])
    

    useEffect(() => {
        // Apply filters based on selectedRating, selectedState, and selectedLGA
        let filtered = stations;

          filtered = filtered.filter(item => item.rating >= 4);
    
        // Filter approved stations (isApproved === true)
        filtered = filtered.filter(item => item.isApproved === true);
    
        setFilteredStations(filtered);
      }, [stations]);
    return (
        <div>
            <section
            className="relative bg-[url(https://media.gettyimages.com/photos/gasoline-station-attendant-pumps-diesel-into-a-car-at-a-filling-on-picture-id97962752)] bg-cover bg-center bg-no-repeat"
            >
            <div
                className="absolute inset-0 bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"
            ></div>

            <div
                className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8"
            >
                <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                <h1 className="text-3xl font-extrabold sm:text-5xl">
                    Find the Nearest 

                    <strong className="block font-extrabold text-rose-700">
                    Filling Stations with Ease!
                    </strong>
                </h1>

                <p className="mt-4 max-w-lg sm:text-xl/relaxed">
                    Discover the closest fuel stations, compare prices, and save on your next fill-up.
                </p>

                <div className="mt-8 flex flex-wrap gap-4 text-center">
                    <Link
                     to="/stations"
                    className="block w-full rounded bg-rose-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
                    >
                    Get Started
                    </Link>

                    <Button
                    href="#feature"
                    className="block w-full rounded bg-white px-12 py-3 text-sm font-medium text-rose-600 shadow hover:text-rose-700 focus:outline-none focus:ring active:text-rose-500 sm:w-auto"
                    >
                    Learn More
                    </Button>
                </div>
                </div>
            </div>
            </section>
            {
                filteredStations.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <div>
                        <section className='pt-5 pb-4' style={{ backgroundColor: '#f0f0f0' }}>
                            <div className='container'>
                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                    <h2 style={{ color: '#333' }}>User Ratings and Reviews</h2>
                                    <p style={{ color: '#666', fontSize: '16px' }}>
                                        Benefit from the collective wisdom of the community. Read real user reviews and ratings to choose stations that consistently deliver exceptional service.
                                    </p>
                                </div>
                                <ReviewsList />
                            </div>
                        </section>
                        <div id='feature' className="container-xxl feature py-5">
                            <div className="container">
                                <div className="row g-5 align-items-center">
                                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                                        <p className="d-inline-block border rounded text-primary fw-semi-bold py-1 px-3">Why Choosing Us!</p>
                                        <h1 className="display-5 mb-4">Find Nearby Stations Effortlessly!</h1>
                                        <p className="mb-4">
                                            Our filling station finder app makes it a breeze to locate the nearest fuel stations in your area. Say goodbye to long, unnecessary detours and wasted time
                                        </p>
                                        <h3 className="mb-3">Save Time and Money</h3>
                                        <p className="mb-5">
                                        By finding the best fuel prices and nearest stations, you'll save time on your commutes and keep more money in your pocket.
                                        </p>
                                        <Link className="btn btn-primary py-3 px-5" to="/sign_up">Sign Up</Link>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-md-6">
                                                <div className="row g-4">
                                                    <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                                        <div className="feature-box border rounded p-4">
                                                            <i className="fa fa-check fa-3x text-primary mb-3"></i>
                                                            <h4 className="mb-3">Filters and Sorting Options</h4>
                                                            <p className="mb-3">
                                                                Customize your search using various filters like price range, distance, and fuel type. Find the best station that suits your preferences.
                                                            </p>
                                                            <Link className="fw-semi-bold" to="">Read More <i className="fa fa-arrow-right ms-1"></i></Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                                        <div className="feature-box border rounded p-4">
                                                            <i className="fa fa-check fa-3x text-primary mb-3"></i>
                                                            <h4 className="mb-3">Real-Time Fuel Prices</h4>
                                                            <p className="mb-3">
                                                            Get access to up-to-the-minute fuel prices so you can make informed decisions and save on your fuel expenses. No more surprises at the pump.
                                                            </p>
                                                            <Link className="fw-semi-bold" to="">Read More <i
                                                                    className="fa fa-arrow-right ms-1"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                                                <div className="feature-box border rounded p-4">
                                                    <i className="fa fa-check fa-3x text-primary mb-3"></i>
                                                    <h4 className="mb-3">Comprehensive Station Information</h4>
                                                    <p className="mb-3">Dive deep into detailed station information, including available fuel types, amenities, ratings, and user reviews. Find the perfect station that meets your needs.</p>
                                                    <Link className="fw-semi-bold" to="">Read More <i
                                                            className="fa fa-arrow-right ms-1"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section style={{
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundImage: 'url("https://th.bing.com/th/id/R.9ea4f7b51b92a1e9c2822939305efe72?rik=R8g0tYlscf1Ebw&riu=http%3a%2f%2fdailypost.ng%2fwp-content%2fuploads%2f2017%2f04%2ffilling-stations.gif&ehk=dQKbxaH71lYAPxhBsANcVycL4vYafS87RSzQUUIdygA%3d&risl=&pid=ImgRaw&r=0")',
                                backgroundBlendMode: 'overlay', // You can change 'overlay' to other blend modes like 'multiply', 'screen', etc.
                                backgroundColor: '#f5f5f5',
                            }}>
                            <div className='container' style={{ textAlign: 'center', padding: '20px'  }}>
                                <h2>Discover Our Top-Rated Stations</h2>
                                <p>Explore the most popular filling stations recommended by our community.</p>
                                <div className="container mb-5 key-feature-grid mt-10 grid grid-cols-2 gap-7 md:grid-cols-3 xl:grid-cols-4">
                                    {
                                        stations && filteredStations.slice(0, 6).map((topStations) => {
                                            return (
                                                <div style={{flexWrap: 'wrap'}} className="flex  justify-between rounded-lg bg-white p-3 shadow-lg">
                                                    <div className='col-sm-12 col-12 col-md-6'>
                                                        <figure style={{ width: '400', marginRight: '15px'}}>
                                                            <img width={500} height={400} src="https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80" alt="ok" style={{ borderRadius: '50%'  }} />
                                                        </figure>
                                                    </div>
                                                    <div className='col-sm-12 col-12 col-md-6'>
                                                        <Link to={`/station/${topStations._id}`} className="h3 text-xl lg:text-2xl">{topStations.name}</Link>
                                                        <p>{topStations.state}</p>
                                                        <p>{topStations.lga}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <div className="flex flex-col justify-between rounded-lg bg-white p-5 shadow-md">
                                        <div>
                                        <h3 className="h4 text-xl lg:text-2xl">Smart Reply</h3>
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                                        </div>
                                        <span className="icon mt-4">
                                        <img className="objec-contain" src="images/icons/feature-icon-2.svg" alt="" />
                                        </span>
                                    </div>
                                    <div className="flex flex-col justify-between rounded-lg bg-white p-5 shadow-lg">
                                        <div>
                                        <h3 className="h4 text-xl lg:text-2xl">Sound Amplifier</h3>
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                                        </div>
                                        <span className="icon mt-4">
                                        <img className="objec-contain" src="images/icons/feature-icon-3.svg" alt="" />
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }
        </div>
    )
}

export default Home;