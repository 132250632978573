import { useEffect, useState } from "react";
import { getUser } from "../../../components/user/UserApi";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import AddUser from "./AddUser";
import PaginationTable from "../../../components/resources/PaginationTable";

const AllUsers = () => {
    const [user, setUser] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = async () => {
        try {
            const data = await getUser();
            setUser(data);
        } catch (error) {
            console.error('Error fetching Users:', error);
        }
    }

    const columns = [
        { id: "username", label: "Username", minWidth: 170 },
        { id: "email", label: "Email", minWidth: 170 },
        { id: "role", label: "Role", minWidth: 170 },
        { id: "isEmailVerified", label: "Email Verified", minWidth: 170 },
        { id: "createdAt", label: "Created At", minWidth: 170 },
        { id: "updatedAt", label: "Updated At", minWidth: 170 },
        { id: "actions", label: "Actions", minWidth: 100 },
      ];

    const createData = (username, email, role, isEmailVerified, createdAt, updatedAt, actions) => {
        return { username, email, role, isEmailVerified, createdAt, updatedAt, actions };
    };
    
    const deleteUser = (userId) => {
        // Implement user deletion logic here
        console.log(`Delete user with ID: ${userId}`);
    };

    const rows = user.map((users) => (
        createData(
          users.username,
          users.email,
          users.role,
          users.isEmailVerified ? "Yes" : "No",
          new Date(users.createdAt).toLocaleDateString(),
          new Date(users.updatedAt).toLocaleDateString(),
          (
            <Button
              variant="outlined"
              onClick={() => deleteUser(users._id)}
              color="error"
            >
              Delete
            </Button>
          )
        )
    ));
    console.log(user);

    return (
        <AdminLayout>
            <div>
                <h2>All Users</h2>
                <Button
                 onClick={
                    (e) => {
                        e.preventDefault();
                        setIsModalOpen(true)
                    }
                }>Add User</Button>
            </div>
            <AddUser isVisible={isModalOpen} onNo={handleCancel} />
            <PaginationTable rows={rows} columns={columns} />
        </AdminLayout>
    )
}

export default AllUsers;
