import React, { useEffect, useState } from "react";
import { getStations } from "../../../components/stations/StationsAPI";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import AdminLayout from "../../../components/Admin/layout/AdminLayout";
import PaginationTable from "../../../components/resources/PaginationTable";

const AllStations = () => {
    const [stations, setStations] = useState([]);
    
    useEffect(() => {
        fetchStations();
    }, []);

    const fetchStations = async () => {
        try {
            const data = await getStations();
            setStations(data.station);
        } catch (error) {
            console.error('Error fetching Stations:', error);
        }
    };

    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 },
        { id: 'station_id', label: 'Station ID', minWidth: 100 },
        {
            id: 'state',
            label: 'State',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'lga',
            label: 'LGA',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'address',
            label: 'Address',
            minWidth: 170,
            align: 'right',
        },
        {
            id: 'average_price',
            label: 'Average Price',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {
            id: 'rating',
            label: 'Rating',
            minWidth: 170,
            align: 'right',
        },
    ];

    const createData = (name, station_id, state, lga, address, average_price, rating) => {
        return { name, station_id, state, lga, address, average_price, rating };
    }

    const rows = stations.map((station) => (
        createData(station.name, station.station_id, station.state, station.lga, station.address, station.average_price, station.rating)
    ));

    return (
        <AdminLayout>
            <div style={{ justifyContent: 'space-between', display: 'flex'}}>
                <h2>All Stations</h2>
                <Button variant="outlined" color="primary">Add Station</Button>
            </div>
            <PaginationTable columns={columns} rows={rows} />
        </AdminLayout>
    )
}

export default AllStations;
