import { Container, TextField, Typography } from "@mui/material";
import { FormModal } from "../../../components/resources/Modals";

const AddUser = ({ isVisible, onNo, form }) => {

    const formContent = (
        <Container maxWidth="lg">
        {/* //     <form className="mx-auto max-w-lg p-4 shadow-lg sm:p-6 lg:p-8"> */}
            
            <TextField
                fullWidth
                variant="outlined"
                label="First Name"
                placeholder="First Name"
            //   value={price}
            //   onChange={(e) => setPrice(e.target.value)}
                sx={{
                mb: 2
                }}
            />

            <TextField
                fullWidth
                variant="outlined"
                label="Last Name"
                placeholder="  Last Name"
            //   value={price}
            //   onChange={(e) => setPrice(e.target.value)}
                sx={{
                mb: 2
                }}
            />

            <TextField
                fullWidth
                variant="outlined"
                label="Email"
                placeholder="  Email"
            //   value={price}
            //   onChange={(e) => setPrice(e.target.value)}
                sx={{
                mb: 2
                }}
            />

            <TextField
                fullWidth
                variant="outlined"
                label="Password"
                placeholder="  Password"
            //   value={price}
            //   onChange={(e) => setPrice(e.target.value)}
                sx={{
                mb: 2
                }}
            />

            <button
                type="submit"
                className="block w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white hover:bg-indigo-700"
            >
                Add User
            </button>
        {/* //     </form> */}
        </Container>
    );

    return(
        <div>
            <FormModal
                title={'Add New User'}
                isVisible={isVisible}
                form={formContent}
                onNo={onNo}
            />
        </div>
    )
}

export default AddUser;